import { createStore as reduxCreateStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

const reducer = (state, action) => {
  if (action.type === 'LOADING_START') {
    return { ...state, isLoading: true }
  }
  if (action.type === 'LOADING_END') {
    return { ...state, isLoading: false }
  }
  if (action.type === 'FIELD_CHANGE') {
    return { ...state, [action.data.name]: action.data.val }
  }
  if (action.type === 'LEAD_SUBMITTED') {
    return { ...state, isLoading: false, leadsubmitted: true }
  }
  if (action.type === 'FORM_SUBMITTED') {
    return { ...state, isLoading: false, formsubmitted: true }
  }
  if (action.type === 'LEAD_SUBMITTED_RESET') {
    return { ...state, isLoading: false, leadsubmitted: false }
  }
  if (action.type === 'SUBSCRIBE_LOADING_START') {
    return { ...state, isSubscribeLoading: true }
  }
  if (action.type === 'SUBSCRIBE_SUBMITTED') {
    return {
      ...state,
      isSubscribeLoading: false,
      subSubmitted: true,
      subSubmittedData: action.data,
    }
  }
  if (action.type === 'CAMPAIGNID_UPDATE') {
    return { ...state, campaignid: action.data }
  }
  if (action.type === 'DESIGNER_PROFILE_START') {
    return {
      ...state,
      isDesignerTemplatesLoading: true,
    }
  }
  if (action.type === 'DESIGNER_PROFILE_UPDATE') {
    return {
      ...state,
      designprofile: action.data,
      isDesignerTemplatesLoading: false,
    }
  }
  if (action.type === 'EXPLORER_PROFILE_START') {
    return {
      ...state,
      isExplorerTemplatesLoading: true,
    }
  }
  if (action.type === 'EXPLORER_PROFILE_UPDATE') {
    return {
      ...state,
      explorerprofile: action.data,
      isExplorerTemplatesLoading: false,
    }
  }
  if (action.type === 'PURCHASE_ERRORS_UPDATE') {
    return {
      ...state,
      purchaseErrors: action.data,
    }
  }
  if (action.type === 'PURCHASE_PLAN_UPDATE') {
    return {
      ...state,
      purchasePlan: action.data,
    }
  }
  if (action.type === 'PURCHASE_LOADING_UPDATE') {
    return {
      ...state,
      isLoadingPurchase: action.data,
    }
  }
  if (action.type === 'PURCHASE_POSITION_UPDATE') {
    return {
      ...state,
      purchasePositionNumber: action.data,
    }
  }
  if (action.type === 'PURCHASE_USERS_UPDATE') {
    return {
      ...state,
      provisionedUsersNumber: action.data,
    }
  }
  if (action.type === 'BLOG_FILTERTAG_UPDATE') {
    return {
      ...state,
      blogFilterTag: action.data,
    }
  }
  if (action.type === 'FULFILL_ORDER_EMAIL') {
    return {
      ...state,
      fulfillOrderEmail: action.data,
    }
  }
  if (action.type === 'EMAIL_INPUT_UPDATE') {
    return {
      ...state,
      emailInput: action.data,
    }
  }
  if (action.type === 'PAYMENT_INTENT_ID_UPDATE') {
    return {
      ...state,
      paymentIntentId: action.data,
    }
  }

  return state
}

const initialState = {
  isExplorerTemplatesLoading: false,
  isDesignerTemplatesLoading: false,
  isSubscribeLoading: false,
  isLoading: false,
  isLoadingPurchase: false,
  leadsubmitted: false,
  formsubmitted: false,
  subSubmitted: false,
  subSubmittedData: {},
  designprofile: {},
  explorerprofile: [],
  purchaseErrors: {},
  purchasePlan: 'standard',
  provisionedUsersNumber: 1,
  purchasePositionNumber: 1,
  blogFilterTag: 'all',
  fulfillOrderEmail: '',
  emailInput: '',
  paymentIntentId: '',
}

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk))

export default createStore
