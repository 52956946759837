// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-explorer-index-js": () => import("./../../../src/pages/explorer/index.js" /* webpackChunkName: "component---src-pages-explorer-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-passadicos-paiva-js": () => import("./../../../src/pages/landing/passadicos-paiva.js" /* webpackChunkName: "component---src-pages-landing-passadicos-paiva-js" */),
  "component---src-pages-prism-index-js": () => import("./../../../src/pages/prism/index.js" /* webpackChunkName: "component---src-pages-prism-index-js" */),
  "component---src-pages-siteguide-index-js": () => import("./../../../src/pages/siteguide/index.js" /* webpackChunkName: "component---src-pages-siteguide-index-js" */),
  "component---src-pages-subscription-confirm-js": () => import("./../../../src/pages/subscription/confirm.js" /* webpackChunkName: "component---src-pages-subscription-confirm-js" */),
  "component---src-pages-subscription-unsubscribe-js": () => import("./../../../src/pages/subscription/unsubscribe.js" /* webpackChunkName: "component---src-pages-subscription-unsubscribe-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/aboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-blog-template-list-js": () => import("./../../../src/templates/blogTemplateList.js" /* webpackChunkName: "component---src-templates-blog-template-list-js" */)
}

