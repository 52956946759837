//React
import React from "react";

const Snippet = () => (
  <div>
    <div class="credits small">
      <hr size="1" />
      <h4>Credits</h4>
      <p>404 icon</p>
      <ul>
        <li>
          Map by Alen Krummenacher from{" "}
          <a
            href="https://thenounproject.com/browse/icons/term/map/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Noun Project
          </a>
        </li>
      </ul>
    </div>
  </div>
);
export default Snippet;
